import React from "react";

export default function Footer() {
  return (
    <div>
      <footer class="bg-indigo-600 text-white py-8 -mx-60 mt-28 ">
        <div class="container mx-auto flex flex-wrap justify-between items-center ">
          <div class="w-full lg:w-1/4 mb-4 lg:mb-0">
            <h4 class="text-lg font-bold mb-4">About Us</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Consectetur voluptatibus quibusdam maiores maxime incidunt
              tenetur.
            </p>
          </div>
          <div class="w-full lg:w-1/4 mb-4 lg:mb-0">
            <h4 class="text-lg font-bold mb-4">Customer Service</h4>
            <ul class="list-none">
              <li>
                <a href="#" class="hover:text-gray-200">
                  My Orders
                </a>
              </li>
              <li>
                <a href="#" class="hover:text-gray-200">
                  Returns and Exchanges
                </a>
              </li>
              <li>
                <a href="#" class="hover:text-gray-200">
                  My Account
                </a>
              </li>
              <li>
                <a href="#" class="hover:text-gray-200">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div class="w-full lg:w-1/4">
            <h4 class="text-lg font-bold mb-4">Contact</h4>
            <p>Adress: 1234 Alışveriş Caddesi, Şehir, Ülke</p>
            <p>Phone: 555-1234</p>
            <p>E-mail: info@example.com</p>
          </div>
        </div>
        <div class="container mx-auto mt-8 text-center">
          <p>&copy; 2023 Shopping site. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}
